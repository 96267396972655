import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { ActualityRequest } from 'src/app/models/actualities/actualityRequest.model';
import { environment } from 'src/environments/environment';
import { Actuality } from '../../models/actualities/actuality.model';
import { Wrapper } from 'src/app/models/wrapper/wrapper.model';

@Injectable({
  providedIn: 'root'
})
export class ActualityService {
  private REST_API_SERVER = '';
  private ACTUALITIES = '';
  private ACTUALITY = '';


  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  public getActualities(actualityRequest: ActualityRequest): Observable<Wrapper<Actuality>> {
    this.ACTUALITIES = this.ressourcePath.getActualities();

    let params = new HttpParams();
    if (actualityRequest.page) {
      params = params.append("page", actualityRequest.page);
    }
    if (actualityRequest.pageSize) {
      params = params.append("pageSize", actualityRequest.pageSize);
    }
    if (actualityRequest.sectorId) {
      params = params.append("sectorId", actualityRequest.sectorId);
    }
    if (actualityRequest.excludeIds && actualityRequest.excludeIds.length > 0) {
      params = params.appendAll({"excludeIds": actualityRequest.excludeIds});
    }
    if (actualityRequest.excludeTagId) {
      params = params.append("excludeTagId", actualityRequest.excludeTagId);
    }
    if (actualityRequest.tagIds && actualityRequest.tagIds.length > 0) {
      params = params.appendAll({"tagIds": actualityRequest.tagIds});
    }
    if (actualityRequest.regionalBranchIds && actualityRequest.regionalBranchIds.length > 0) {
      params = params.appendAll({ "regionalBranchIds": actualityRequest.regionalBranchIds })

    }
    if (actualityRequest.technicalsTagCodes && actualityRequest.technicalsTagCodes.length > 0) {
      params = params.appendAll({ "technicalsTagCodes": actualityRequest.technicalsTagCodes });
    }
    return this.http.get<Wrapper<Actuality>>(this.REST_API_SERVER + this.ACTUALITIES, { params });
  }

  public getActualityById(id: number): Observable<Actuality> {
    this.ACTUALITY = this.ressourcePath.getActualityById(id);
    return this.http.get<Actuality>(this.REST_API_SERVER + this.ACTUALITY);
  }

  public updateActualityLikes(id: number, likes: number): Observable<any> {
    let params = new HttpParams();
    params = params.appendAll({ "like": likes });
    return this.http.put(this.REST_API_SERVER + this.ACTUALITY + "?like="+likes, { params});
  }



  public getActualitiesRSS(): Observable<any> {

    return this.http.get('https://cloud.amisw.com/rss/rss.php?cc=PIzFCSAgpkfZ6kHh8dTAVmgr2pu-RyBW1g4C6DDHtT8lp8fJAgK-BwGqkQg5ONIFd1uRuB6Ugi5zFnFsxCGKTxfqcR1_ebscew', { responseType: 'text' });
  }
}
