import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { SearchedKeyword } from 'src/app/models/searchedkeyword/searchedkeyword.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchedkeywordService {

  constructor(private http: HttpClient, private urls: GlobalURLs) { }

  public save(searchedkeyword: SearchedKeyword): Observable<SearchedKeyword> {

    return this.http.post<SearchedKeyword>(environment.apiUrl+this.urls.apiSearchedKeyword, searchedkeyword);

  }
}
