import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {

  const userAgent = navigator.userAgent.toLowerCase();

  if (!userAgent.includes('prerender')) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//cdn.tagcommander.com/3480/tc_Bpifrance_1.js';

    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = '//cdn.tagcommander.com/3480/tc_Bpifrance_2.js';

    document.body.appendChild(script2);
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
