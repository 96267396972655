<section class="page-header">
  <div class="page-header__container" [ngClass]="{'page-header__container-euroquity':isEuroquity ,'page-header__size':isAdvisor }">
    <div
      class="page-header__content" [ngClass]="{'page-header__content-euroquity' : isEuroquity,'showButton' : isObs }"
      [class.page-header__content_variant_text-only]="!headerImage"
    >
      <button type="button" class="page-header__back" (click)="back()">
        <dep-icon class="icon" [icon]="'icon-arrow_back_ios'"></dep-icon>
        Retour
      </button>

      <div class="page-header__breadcrumb">
        <ng-content select="[breadcrumb]"></ng-content>
      </div>

      <h1 class="page-header__title"
      [ngClass]="{'page-header__title-size':isInscriptionForm ,'page-header__title-small':isAdvisor}">{{headerTitle}}</h1>
      
      <div class="page-header__text">
        <ng-content select="[slot='text']"></ng-content>
      </div>

      <div class="page-header__cta">
        <ng-content select="[slot='cta']"></ng-content>
      </div>

      <div class="page-header__promise">
        <ng-content select="[slot='promise']"></ng-content>
      </div>

      <div class="page-header__image" *ngIf="headerImage">
        <img class="defaultimg" [ngClass]="{'img_resize':isObs ,'img_small':isEuroquity ,'img_advisor':isAdvisor}" [src]="headerImage" alt="">
        <img *ngIf="isTandem" class="tandem_img" src="assets/images/tandem_logo.png" alt="Tandem logo">
        <img *ngIf="isEuroquity" class="euroquity_img" src="assets/images/euroquity/logo.png" alt="Euroquity logo">
        <img *ngIf="isAdvisor" class="tandem_img" src="assets/images/tango.png" alt="Tango logo">
      
        <div class="page-header__button" *ngIf="isObs">
          <a href="https://observatoire.lesdeeptech.fr" target="_blank" rel="noopener noreferrer"
            class="ds-btn ds-btn--primary">Accéder à l’Observatoire</a>
        </div>
      </div>

    </div>
  </div>
</section>
