import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { AuthService } from 'src/app/shared/services/authentification/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuidedTourService {
  private currentStep: number=0;

 
  public stepChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor(private authService: AuthService) {

    const localStep=localStorage.getItem("guidedTourStep")!=null?Number.parseInt(localStorage.getItem("guidedTourStep")):4;

    if(localStep<4 && localStep>0){
      this.currentStep=localStep
    }else{
      this.authService.userSubject.subscribe(
        user => {
          if (user.firstConnection) {
            this.currentStep = 1;
          } else {
            this.currentStep = 4;
          }
        }
      )
    }
    

  }

  nextStep() {
    this.currentStep++;
    localStorage.setItem("guidedTourStep",this.currentStep.toString())
    this.stepChanged.emit(this.currentStep);
    if (this.currentStep > 3){
      localStorage.setItem("guidedTourStep","4")
      
    }
      
  }



  getCurrentStep() {
    return this.currentStep;
  }

  closePopUp() {
    this.currentStep = 4;
    localStorage.setItem("guidedTourStep","4")
    this.stepChanged.emit(this.currentStep);
    
  }

}