<nav class="breadcrumb" [attr.aria-label]="breadcrumbLabel" [ngClass]="{'breadcrumb__container': isBtn}">
  <ol class="breadcrumb__wrapper">
    <ng-container *ngFor="let item of breadcrumbItems; let i = index">
      <li class="breadcrumb__item" [attr.aria-current]="i === breadcrumbItems.length - 1 ? 'page': null">
        <ng-container [ngTemplateOutlet]="item.itemTemplate"></ng-container>
      </li>
    </ng-container>
  </ol>
  <ng-container *ngIf="isBtn">
    <div  class="btn__container">
      <button class="btn" [routerLink]="['/atlas-de-la-deeptech']">
        <span class="ds-btn ds-btn--primary" >{{btnLabel}}</span>
      </button>
    </div>
 </ng-container>
  
</nav>
