import { APP_BASE_HREF, DatePipe, Location, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule, SecurityContext } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { MarkdownModule } from 'ngx-markdown';
import { GlobalURLs } from './GlobalURLs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth-config.module';
import { BasicElementsModule } from './components/basic-elements/basic-elements.module';
import { BlocksModule } from './components/blocks/blocks.module';
import { BuildBusinessPlanComponent } from './components/view/create-startups/build-business-plan/build-business-plan.component';
import { BuildTeamComponent } from './components/view/create-startups/build-team/build-team.component';
import { CoachEcosystemComponent } from './components/view/create-startups/coach-ecosystem/coach-ecosystem.component';
import { CreateStartupsComponent } from './components/view/create-startups/create-startups.component';
import { DeclareInventionComponent } from './components/view/create-startups/declare-invention/declare-invention.component';
import { FinanceProjectComponent } from './components/view/create-startups/finance-project/finance-project.component';
import { MarketAccessComponent } from './components/view/create-startups/market-access/market-access.component';
import { ProtectInventionComponent } from './components/view/create-startups/protect-invention/protect-invention.component';
import { ResearchResultComponent } from './components/view/create-startups/research-result/research-result.component';
import { TransferTechnologiesComponent } from './components/view/create-startups/transfer-technologies/transfer-technologies.component';
import { NotFoundComponent } from './components/view/not-found/not-found.component';
import { UnderConstructionComponent } from './components/view/under-construction/under-construction.component';
import { UnderMaintenanceService } from './shared/guards/under-maintenance-auth-guard';
import { SharedModule } from './shared/shared.module';
import { LanguageRedirectComponent } from './language-redirect.component';

registerLocaleData(localeFr);
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = {
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL }
  } as any;
}

export function storageFactory() : OAuthStorage {
  return localStorage
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    UnderConstructionComponent,

    // * contests module

    // * create-startups module
    CreateStartupsComponent,
    TransferTechnologiesComponent,
    BuildTeamComponent,
    ProtectInventionComponent,
    CoachEcosystemComponent,
    MarketAccessComponent,
    BuildBusinessPlanComponent,
    ResearchResultComponent,
    DeclareInventionComponent,
    FinanceProjectComponent,
    LanguageRedirectComponent

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    FeatureToggleModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE, // turn off sanitization
    }),
    ReactiveFormsModule,
    // new modules
    BasicElementsModule,
    BlocksModule,
    HammerModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          'http://localhost:9091/', 
          'https://dep.dev.api.cloud.bpifrance.fr/deeptech', 
          'https://dep.staging.api.cloud.bpifrance.fr/deeptech',
          'https://dep.prd.api.cloud.bpifrance.fr/deeptech',
        ],
        sendAccessToken: false
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthConfigModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: UnderMaintenanceService },
    GlobalURLs,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    DatePipe,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: Window, useValue: window },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    CookieService,
   
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
// const __stripTrailingSlash = (Location as any).stripTrailingSlash;
// Location.stripTrailingSlash = function (url) {
//   if (url.endsWith('.html')) {
//     return url;
//   } else if (url.endsWith('/') || url.includes('?')) {
//     url = url;
//   }
//   else {
//     url = url + '/';
//   }
//   const queryString$ = url.match(/([^?]*)?(.*)/);
//   if (queryString$[2].length > 0) {
//     return /[^\/]\/$/.test(queryString$[1])
//       ? queryString$[1] + '.' + queryString$[2]
//       : __stripTrailingSlash(url);
//   }
//   return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
// };


