<dep-main-header></dep-main-header>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Déclarer une invention">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup'" *appBreadcrumbItem>Envie
        d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Déclarer une invention</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>

  <!-- La déclaration d’invention, point de départ du processus de valorisation -->
  <dep-page-section sectionTitle="La déclaration d’invention, point de départ du processus de valorisation"
    sectionTheme="medium">
    <article class="article">
      <dep-text>
        <p>
          La première évaluation du potentiel de valorisation économique d’un résultat de recherche passe par la
          rédaction de la déclaration d’invention.
        </p>
        <p>
          Ce document permet :
        </p>
        <ol>
          <li>
            La description technique du résultat de recherche en vue de sa protection sous forme de <a
              [routerLink]="'/creer-sa-startup/protection-propriete-intellectuelle.html'">brevet</a>.
          </li>
          <li>
            L’identification du contexte dans lequel l’invention a été mise au point (partenariats, laboratoires
            impliqués etc…).
          </li>
        </ol>
      </dep-text>
    </article>
  </dep-page-section>
  <!-- FIN de La déclaration d’invention, point de départ du processus de valorisation -->

  <!-- Contenu éditorial -->
  <dep-page-section>
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Pourquoi remplir une déclaration d’invention ?</h2>

          <h3>Obligation légale</h3>

          <p>La déclaration d’invention est un document légal qui répond à des contraintes juridiques (signatures des
            différentes parties, délais de traitement, confidentialité des données etc…). Tout chercheur salarié qui
            réalise une invention a l’obligation de la déclarer à son employeur (qu’il s’agisse d’une invention en
            mission ou hors mission).</p>

          <h3>Intérêt pour le projet</h3>

          <p>Ce document est le premier pas vers une possible protection de l’invention. Il est aussi le point de départ
            du processus de valorisation vers la création de votre entreprise.</p>

          <p>On en distingue 3 grands types :</p>

          <ol>
            <li>
              Les déclarations d’invention « généralistes » valables pour la plupart des champs d’application
            </li>
            <li>
              Les déclarations d’invention logiciel
            </li>
            <li>
              Les déclarations d’invention en santé
            </li>
          </ol>

          <p><strong>Si vous êtes chercheur : nous vous invitons à prendre contact avec la <a
                href="https://www.curie.asso.fr/-Valorisation-Transfert-de-technologie-.html" target="_blank"
                rel="noopener noreferrer">structure de valorisation</a> en charge de la déclaration d’invention pour
              votre laboratoire.</strong></p>

          <h2>Découvrez des modèles de DI</h2>

          <p>La déclaration d’invention est un document qui peut varier d’un établissement de recherche à un autre. Ce
            document est donc téléchargeable à titre d’exemple. Il est issu d’un travail collaboratif entre plusieurs
            structures de valorisation en charge du transfert de technologie et reprend les éléments essentiels communs.
          </p>
        </div>
      </div>

      <!-- Fichiers en téléchargement -->
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <dep-documents-list>
            <dep-document class="lg-grid-col-4" document="/assets/docs/di-generaliste.docx"
              documentName="DI Généraliste" documentSize="30ko">
            </dep-document>

            <dep-document class="lg-grid-col-4" document="/assets/docs/di-sante.pdf" documentName="DI Santé"
              documentSize="290ko">
            </dep-document>

            <dep-document class="lg-grid-col-4" document="/assets/docs/di-logiciel.pdf" documentName="DI Logiciel"
              documentSize="813ko">
            </dep-document>
          </dep-documents-list>
        </div>
      </div>
    </article>
  </dep-page-section>
  <!-- FIN de Contenu éditorial -->

  <!-- Les parties prenantes clés -->
  <dep-page-section sectionTitle="Les parties prenantes clés" sectionTheme="medium">
    <div class="grid-container">
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        Les chercheurs
      </dep-icon-text>

      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        Les établissements de recherche publique
      </dep-icon-text>

      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        La structure de valorisation
      </dep-icon-text>
    </div>
  </dep-page-section>
  <!-- FIN de Les parties prenantes clés -->

  <!-- Les autres composantes -->
  <dep-page-section sectionTitle="Les autres composantes">
    <div class="grid-container">
      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/protection-propriete-intellectuelle.html']">Protéger une
          invention</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/entreprendre-en-equipe.html']">Monter une équipe fondatrice</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/plan-de-financement.html']">Concevoir son business plan</a></dep-step>

    </div>
  </dep-page-section>
  <!-- FIN de Les autres composantes -->
</main>

<dep-main-footer></dep-main-footer>