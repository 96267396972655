<dep-main-header class="main-header"></dep-main-header>

<main class="main">
  <!-- Page header -->
  <dep-page-header-simple class="page-header-wrapper">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/me-former/.'" *appBreadcrumbItem>Développer ses
        compétences</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Toutes les formations</dep-breadcrumb-item>
    </dep-breadcrumb>
    <!-- Page Title -->
    <ng-container pageTitle>Toutes les formations</ng-container>
  </dep-page-header-simple>

  <!-- 'Actualités' section -->
  <dep-page-section-actualities [sectionTheme]="'light'">
    <div class="list-title-filter">
      <!-- Title -->
      <h2 class="list-title">Qu’est-ce qui vous intéresse&nbsp;?</h2>
      <!-- Filters -->
      <div *ngIf="tags.length > 0" class="filters-wrapper">
        <dep-list-filter-combined [filterName]="'Filtrer'" [filterValue]="'Tous les sujets'" [tagsList]="tags"
          (trainingFilterEvent)="getFilteredTrainings($event)"></dep-list-filter-combined>
      </div>
    </div>
    <!-- List of the last formations -->
    <dep-training-list id="formations-list" [trainings]="trainings"></dep-training-list>

    <div class="list-footer" aria-live="polite">
      <!-- Progress bar (displays the percentage of loaded formations) -->
      <div class="list-footer__progress" aria-hidden="true" #progressbar *ngIf="!filtered"></div>
      <!-- Counter -->
      <p class="list-footer__counter" *ngIf="!filtered">{{trainingsDisplayed}} formations sur {{trainingsTotal}}</p>
      <!-- 'Load more' button -->
      <button *ngIf="isValidated" area-label="load more" class="list-footer__button" (click)="loadMoreformations()"
        aria-controls="formations-list">Afficher plus</button>
    </div>
  </dep-page-section-actualities>
</main>

<dep-main-footer></dep-main-footer>