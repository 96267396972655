import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-language-redirect',
  template: '',
})
export class LanguageRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // Subscribe to route changes
    this.route.url.subscribe(() => {
      this.handleRouteChange();
    });
  }

  private handleRouteChange(): void {
    const currentUrl = this.router.url; // URL complète de la route active
    // console.log('Current URL:', currentUrl);

    if (currentUrl.startsWith('/en/') || currentUrl.startsWith('/fr/')) {
      const segments = currentUrl.split('/');
      const selectedLanguage = segments[1];

      // Stocker la langue dans le local storage
      localStorage.setItem('selectedlanguage', selectedLanguage);

      if (segments[2] === 'fr' || segments[2] === 'en') {
        segments.splice(2, 1);
        const newUrl = segments.join('/');
        this.router.navigateByUrl(newUrl);
      } 
      else {
        // Rediriger vers la page 404
        this.router.navigate([`/${selectedLanguage}/page-404`]);
      }
    } 
    else {
      const storedLanguage = localStorage.getItem('selectedlanguage') || 'fr';
      let url = currentUrl ==='/' ? '' : currentUrl;
      const newUrl = `/${storedLanguage}${url}`;
      this.router.navigateByUrl(newUrl);
    }
  }
}