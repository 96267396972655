<dep-main-header></dep-main-header>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Créer la startup et intégrer la propriété intéllectuelle" [longTitle]="true">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup'" *appBreadcrumbItem>Envie
        d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Créer la startup et intégrer la propriété
        intéllectuelle</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>

  <!-- Réussir la création de sa société et négocier le transfert de technologie -->
  <dep-page-section sectionTitle="Réussir la création de sa société et négocier le transfert de technologie"
    sectionTheme="medium">
    <dep-text>
      <p>
        Ca y est ! Après toutes ces étapes pour structurer votre projet il est temps de concrétiser vos efforts, donner
        naissance à votre entreprise et officialiser le transfert de la propriété intellectuelle vers votre startup.
      </p>
      <p>
        Trois documents importants encadrent ces procédures : les <b>statuts juridiques</b> et le <b>pacte
          d’actionnaire</b> pour la création de votre société et le <b>contrat de licence</b> pour le transfert de PI.
      </p>
      <p>
        Des bonnes pratiques accompagnent ces étapes, en particulier autour de la négociation d’un contrat de licence
        avec la structure de transfert de technologie mandatée pour le faire.
      </p>
    </dep-text>
  </dep-page-section>
  <!-- FIN de Réussir la création de sa société et négocier le transfert de technologie -->

  <!-- Contenu éditorial -->
  <dep-page-section sectionTitle="Trois contrats clés pour créer sa startup">
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <p>Les trois documents clés présentés sont des documents juridiques. Il est important de bien comprendre et
            d’appréhender la signification des principales clauses. Vous trouverez ci-dessous des explications pour vous
            y aider mais il peut être pertinent de souscrire un conseil juridique.</p>

          <!-- Tableau -->
          <table id="t01">
            <tr>
              <th style="width:120px;">Document</th>
              <th style="width:170px;">Statuts juridiques</th>
              <th style="width:190px;">Pacte d'actionnaires</th>
              <th>Contrat de licence de PI</th>
            </tr>
            <tr>
              <td>C’est quoi ?</td>
              <td>le cadre légal qui s’impose à la société (ex. régime fiscal, régime social etc…) </td>
              <td>un contrat qui encadre les conditions de gestion de la société et de son capital par les actionnaires
              </td>
              <td>un contrat commercial qui encadre les conditions d’exploitation d’une propriété intellectuelle détenue
                par un labo par une société</td>
            </tr>
            <tr>
              <td class="bg-tab-row">Quand le signer ?</td>
              <td class="bg-tab-row">A la création de l’entreprise (document nécessaire à la création)</td>
              <td class="bg-tab-row">A la création de la société puis à chaque intégration d’un nouvel actionnaire
                (document facultatif)</td>
              <td class="bg-tab-row">Au transfert de l’actif de PI (avant que la startup exploite la PI commercialement)
              </td>
            </tr>
            <tr>
              <td>Qui doit le signer ?</td>
              <td>Les associés fondateurs</td>
              <td>Toute personne qui détient du capital dans la société</td>
              <td>La startup et la structure de valorisation</td>
            </tr>
          </table>
          <!--  -->
        </div>
      </div>
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h3><span class="yellow-text">1.</span> Les statuts d’entreprise</h3>
          <p>
            Les statuts, ou forme juridique, définissent l’existence de l’entreprise et en déterminent les règles de
            fonctionnement. Le choix des statuts impacte également le régime fiscal de votre entreprise et de ses
            dirigeants, le régime social applicable, les obligations comptables et les processus de décision au sein de
            l’entreprise.
          </p>
          <p>
            Les statuts d’une entreprise peuvent évoluer dans le temps (intégration de nouveaux associés, introductions
            en bourse etc…).
          </p>
          <p>
            Bien qu’il existe beaucoup de formes juridiques en France, la SAS ou Société par Actions Simplifiée est la
            forme la plus couramment choisie par les startups deeptech (env. 90%).
          </p>
        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-idea ideaTitle="La SAS, société par actions simplifiées" ideaAlignOffset="medium"
            externalRedirection="https://bpifrance-creation.fr/encyclopedie/structures-juridiques/entreprendre-a-plusieurs/sas-societe-actions-simplifiee"
            titleLink="Les SAS">
            Découvrez cette forme juridique sur Bpifrance Université
          </dep-idea>
        </aside>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h3><span class="yellow-text">2.</span> Le pacte d’actionnaire</h3>

          <p>
            Le pacte d’actionnaire est un contrat qui organise les devoirs et responsabilités des associés au sein d’une
            société. Il est souvent comparé à un contrat de mariage car il définit les propriétés, les transferts de
            propriétés et les méthodes de résolution de conflit en cas de désaccords entre associés. C’est un document
            libre qui peut contenir des clauses variées.
          </p>
          <p>
            Le pacte d’actionnaire sera revu et signé par tout nouvel actionnaire de la société. Bien qu’il ne soit pas
            obligatoire, rédiger un pacte d’actionnaires est une bonne pratique à laquelle nous vous conseillons de
            souscrire. Il est d’ailleurs nécessaire du point de vue des investisseurs financiers.
          </p>
        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-resource resourceTitle="Clausier pacte d’actionnaires" resourceAlignOffset="medium"
            resource="/assets/docs/pacte-dactionnaire.pdf">
            Comprendre les clauses fréquemment rencontrées dans ce document.
          </dep-resource>
        </aside>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h3 id="AnchorId"><span class="yellow-text">3.</span> Le contrat de licence</h3>

          <p>
            Le transfert de technologie signifie l’acquisition du droit d’exploitation de la propriété intellectuelle
            (ou sa cession) de la structure de valorisation, mandatée par l'institution qui en est propriétaire, vers la
            startup. Ce transfert fait l’objet d’un contrat de licence ou de cession qui détaille les conditions
            d’utilisation et de paiement par la startup. Ce contrat reprend notamment <a
              href="https://transfertostartup.com/" target="_blank" rel="noopener noreferrer">les éléments énoncés dans
              le termsheet.</a>
          </p>
          <p>
            Le contrat de licence (ou de cession) fait l’objet d’une négociation commerciale entre la startup et la
            structure de valorisation. Il est impératif de bien comprendre les clauses de ce document qui ont un impact
            direct sur le business plan de l’entreprise (actif immatériel, plan de trésorerie, besoin de financement,
            valorisation de la société…).
          </p>
        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-resource resourceTitle="Clausier de contrat de licence" resourceAlignOffset="medium"
            resource="/assets/docs/la-licence-expliquee-vdef.pdf">
            Comprendre les clauses fréquemment rencontrées dans ce document.
          </dep-resource>
        </aside>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Bonnes pratiques : la négociation du contrat et les contreparties</h2>

          <p>
            La négociation du contrat est cruciale pour la startup et comporte plusieurs objectifs correspondant aux
            enjeux respectifs de la startup, de la structure de valorisation, des institutions copropriétaires de la
            propriété intellectuelle et des inventeurs :
          </p>
          <ul class="content-list">
            <li>Construire un modèle de concession de droits de PI cohérent avec l’exploitation et le business model
              envisagé par la start-up,</li>
            <li>S’accorder sur le bon niveau de contreparties financières pour satisfaire l’investissement de la
              structure de valorisation et les copropriétaires et inventeurs sans pénaliser le développement futur de
              l’entreprise,</li>
            <li>Organiser les relations entre la société et les copropriétaires souvent amenés à poursuivre leur
              collaboration
              au-delà du transfert.</li>
          </ul>
          <p>
            Il existe plusieurs clauses financières susceptibles d’être activées dans un contrat de licence : l’upfront,
            les redevances (« royalties »), les redevances sur sous-licence, les milestones, les sommes forfaitaires ou
            encore la cession de l’actif de PI. Pour creuser ces points nous vous invitons à télécharger <a
              (click)="onClick('AnchorId')"><u>le clausier de contrat de licence.</u></a>
          </p>
          <p>
            Par ailleurs, tout ou partie de ces sommes peuvent être alternativement payées en espèces ou faire l’objet
            d’une prise de participation par conversion de créances.
          </p>
          <p>
            Certaines structures de valorisation prennent des participations en espèces au tour fondateur.
          </p>
          <p>
            A ce stade, le projet est encore très risqué et compliqué à valoriser pour la structure comme pour les
            fondateurs. Il est néanmoins important de limiter la durée de la négociation (impact sur la trésorerie du
            projet et des fondateurs ainsi que sur l’obsolescence de la PI) et de baser la discussion sur des éléments
            rationnels, et une vision partagée du développement de la technologie transférée.
          </p>

          <h3>Renseignez-vous sur les pratiques courantes des structures de votre secteur</h3>

          <p>
            Une étude récente sur la prise de parts montre ainsi que :
          </p>
          <ul class="content-list">
            <li>La prise participation des SATT dans les startups à leur création se situe en moyenne autour de 10%,
            </li>
            <li>La prise de part au titre de l’accompagnement du CEA dans les startups issues de ses laboratoires au
              moment de leur création est en moyenne de 15%,</li>
            <li>L’INRIA Startup Studio rentre au capital de toutes les sociétés accompagnées a minima à 5%.</li>
          </ul>
          <p>
            A noter que ces chiffres sont des moyennes et peuvent varier fortement dans la pratique.
          </p>
          <p>
            Le document suivant présente de manière pédagogique les clauses que vous pourriez rencontrer dans un contrat
            de licence proposé par votre structure de valorisation référente.
          </p>

          <h3>Rédigez un business plan solide et argumenté</h3>

          <p>
            La valeur d’une entreprise dépend avant tout des futurs cashflows (et non des dépenses engagées dans le
            passé).<br /><br />
            L’estimation des cashflows futurs d’un projet deeptech est particulièrement compliquée compte tenu du temps
            de développement de l’entreprise. La valeur de votre projet se base néanmoins sur des éléments clés de votre
            business plan : la taille de vos marchés, votre business model et sa scalabilité, les premiers retours
            clients, la maturité de votre technologie, la solidité de votre PI et la capacité d’exécution de votre
            équipe.<br /><br />
            Adopter dès ce stade le point de vue d’un investisseur vous permettra de challenger et expliquer les
            hypothèses retenues dans votre business plan.

          </p>

          <h3>Elaborez avec la structure de valorisation votre stratégie de levées de fonds </h3>

          <p>
            L’établissement d’une table de capitalisation prévisionnelle évitera d’éventuelles déconvenues. En effet en
            vous projetant dans les tours de levée de fonds futurs, il est possible de vous rendre compte qu’un
            désaccord aujourd’hui de quelques milliers d’euros sur la valorisation peut être insignifiant à des tours
            ultérieurs (en série A ou B par exemple).

          </p>

          <h3>Quelques points d’attention</h3>

          <ul class="content-list">
            <li>Une valorisation trop haute peut mettre à risque les levées de fonds futures : négociation longue et
              risque d’une mauvaise « equity story » (ex. pas de réel mark up entre deux valorisations),
            </li>
            <li>Les contreparties financières trop élevées peuvent impacter la croissance organique de l’entreprise et
              sa recherche de nouveaux clients (et donc à terme d’investisseurs),</li>
            <li>Une PI non exclusive dans certains secteurs peut être un « deal breaker » pour des investisseurs qui
              préfèrent que la startup soit propriétaire de ses actifs de PI (l’exclusivité étant dans ce cas un minima,
              et une option d’achat dans la licence un complément idéal).</li>
          </ul>
        </div>
      </div>
    </article>
  </dep-page-section>
  <!-- FIN de Contenu éditorial -->

  <!-- Les parties prenantes clés -->
  <dep-page-section sectionTitle="Les parties prenantes clés" sectionTheme="medium">
    <div class="grid-container">
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        Porteurs de projet
      </dep-icon-text>
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        Structure de valorisation
      </dep-icon-text>
    </div>
  </dep-page-section>
  <!-- FIN de Les parties prenantes clés -->

  <!-- Les autres composantes -->
  <dep-page-section sectionTitle="Les autres composantes">
    <div class="grid-container">

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/financer-son-projet.html']">Financer son projet</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/accompagnement-startup.html']">Se faire accompagner</a></dep-step>

    </div>
  </dep-page-section>
  <!-- FIN de Les autres composantes -->
</main>

<dep-main-footer></dep-main-footer>