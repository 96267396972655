import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/authentification/auth.service';
import { SharedService } from '../../services/shared/shared.service';
import { User } from 'src/app/models/user/user';
import { HabilitationName } from 'src/app/models/user/habilitation.enum';

@Component({
  selector: 'dep-partner-space-header',
  templateUrl: './partner-space-header.component.html',
  styleUrls: ['./partner-space-header.component.scss']
})
export class PartnerSpaceHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  // isLoaded: boolean = false;
  menuIsOpen: boolean = false;
  selectedItem: string = '';
  mainNav = null;
  isProfil: boolean = false;
  myCommunityURL = environment.myCommunityURL;
  isCommunity: boolean = false;
  innerWidth: number;
  isMobile: boolean = false;
  // user!:Observable<User>;
  menuStates: { [key: string]: boolean } = {};
  activeParentItem: string;
  eventType: string;
  public user: User;
  public showContact: Boolean;
  test = false;

  constructor(
    router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private renderer: Renderer2,

  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.isProfil = window.location.href.endsWith("/profil.html");
      }
      if (event instanceof NavigationEnd) {
        this.isProfil = window.location.href.endsWith("/profil.html");
      }
    });

    route.queryParams.subscribe(params => {
      this.getSelectedItem(params['type']);
    });

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.closeMenu();
      // active route
      const currentUrl = router.url;
      if (currentUrl.includes('vivier-de-temoins/') || currentUrl.includes('deeptech-campus/')) {
        this.activeParentItem = 'sensibiliser';
      } else if (currentUrl.includes('tandem/') || currentUrl.includes('tango/') || currentUrl.includes('communaute-de-talents/')) {
        this.activeParentItem = 'recruter';
      } else if (currentUrl.includes('bourse-french-tech-lab/') || currentUrl.includes('euroquity/')) {
        this.activeParentItem = 'financer';
      } else if (currentUrl.includes('calendrier-des-evenements/') || currentUrl.includes('les-actualites/')) {
        this.activeParentItem = 'chiffres-et-actualités';
      } else {
        this.activeParentItem = '';
      }
    });
  }

  ngOnInit(): void {
    
    this.authService.userSubject.subscribe( 
      result=> this.user=Object.assign(new User(), result)
    );
   
    this.showContact=this.user.showContact;
    this.sharedService.eventTypeSubject.subscribe(
      result => {
        this.eventType = result;
      }
    )
    this.detectWindowSize();
  }

  ngAfterViewInit(): void {
    this.mainNav = document.querySelector('#main-nav');
  }

  ngOnDestroy(): void {
    clearAllBodyScrollLocks();
  }

  toggleMenu(item: string): void {
    this.menuStates[item] = !this.menuStates[item];

    Object.keys(this.menuStates).forEach(key => {
      if (key !== item) {
        this.menuStates[key] = false;
      }
    })
  }


  closeMenu(): void {
    Object.keys(this.menuStates).forEach(key => {
      this.menuStates[key] = false;
    });

    // Re-enable body scroll
    enableBodyScroll(this.mainNav);
  }

  logout() {
    this.authService.logout();
  }

  getSelectedItem(item: string): void {
    this.selectedItem = item;
  }


  detectWindowSize(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 599) {
      this.isMobile = true;
    }
  };
}
