import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { DirectAccess } from 'src/app/models/direct-access/directAccess.model';
import { Favorite } from 'src/app/models/favorites/favorite.model';
import { FavoriteRequest } from 'src/app/models/favorites/favoriteRequest.model';
import { ReferenceType } from 'src/app/models/favorites/referenceType';
import { environment } from 'src/environments/environment';

import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { DirectAccessService } from '../direct-access/direct-access.service';
import { PartnersService } from '../partners/partners.service';
import { ServicesService } from '../services/services.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  private REST_API_SERVER = '';
  private FAVORITES = '';

  public favorites: Favorite<any>[] = [];
  public favoritesBehavior = new BehaviorSubject<Favorite<any>[]>([]);
  public favorites$ = this.favoritesBehavior.asObservable();


  public favoritesDirectAccess: DirectAccess[] = [];

  public favoritesDirectAccessTemp: DirectAccess[] = [];
  public favoritesDirectAccessBehaviorTemp = new BehaviorSubject<DirectAccess[]>([]);
  public favoritesDirectAccessTemp$ = this.favoritesDirectAccessBehaviorTemp.asObservable();

  public directAccessChangeBehaviour = new BehaviorSubject<Boolean>(true);
  public directAccessChange$ = this.directAccessChangeBehaviour.asObservable();


  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs,
    private sharedService: SharedService,
    private servicesService: ServicesService,
    private partnerService: PartnersService,
    private directAccessService: DirectAccessService
  ) {
    this.REST_API_SERVER = environment.apiUrl;
    if (!this.getFavoritesFromLocal()) {
      this.setFavoritesToLocal([]);
    }
    else {
      this.refreshPage();
    }

  }


  public getFavorites(favortiteRequest: FavoriteRequest) {
    const httpOptions = this.sharedService.getSecureHeaders();
    this.FAVORITES = this.ressourcePath.getFavorites();
    return this.http.post<Favorite<any>[]>(this.REST_API_SERVER + this.FAVORITES, favortiteRequest, httpOptions)
  }

  public addFavoriteRequest(favorite: Favorite<any>): Observable<Favorite<any>> {
    const httpOptions = this.sharedService.getSecureHeaders();
    this.FAVORITES = this.ressourcePath.getFavorites();
    return this.http.put<Favorite<any>>(this.REST_API_SERVER + this.FAVORITES, favorite, httpOptions);
  }

  public deleteFavoriteRequest(favorite: Favorite<any>): Observable<Favorite<any>> {
    const httpOptions = this.sharedService.getSecureHeaders();
    httpOptions.body = favorite;
    this.FAVORITES = this.ressourcePath.getFavorites();
    return this.http.delete<Favorite<any>>(this.REST_API_SERVER + this.FAVORITES, httpOptions);
  }

  init(user): void {

    this.clearFavorites();
    this.setFavoritesToLocal([]);

    // get all favorites
    let favoriteRequest = new FavoriteRequest();
    favoriteRequest.referenceTypes = [ReferenceType.PARTNER, ReferenceType.SERVICE, ReferenceType.DIRECT_ACCESS, ReferenceType.CONTACT];

    this.getFavorites(favoriteRequest).subscribe(
      response => {
        response.forEach(favorite => {
          switch (favorite.referenceType.toString()) {
            case ReferenceType[ReferenceType.PARTNER]: {
              this.partnerService.getPartnerById(parseInt(favorite.referenceId)).subscribe(
                response => {
                  if (response) {
                    let fav = new Favorite();
                    fav.content = response;
                    fav.referenceId = response.id.toString();
                    fav.referenceType = response.type;
                    this.createFavorite(fav);
                  }
                }
              )
              break;
            }
            case ReferenceType[ReferenceType.SERVICE]: {
              this.servicesService.getServiceById(user, parseInt(favorite.referenceId)).subscribe(
                response => {
                  if (response) {
                    let fav = new Favorite();
                    fav.content = response;
                    fav.referenceId = favorite.id.toString();
                    fav.referenceType = favorite.referenceType;
                    this.createFavorite(fav);
                  }
                }
              )
              break;
            }
            case ReferenceType[ReferenceType.CONTACT]: {
              favorite.referenceType = ReferenceType.CONTACT;
              this.createFavorite(favorite);
              break;
            }
            default:
              break;
          }
        });
      }
    );

    // get favorite direct access
    this.directAccessService.getFavoriteDirectAccesses().subscribe(
      response => {
        this.favoritesDirectAccess = response;
        this.addDirectAccessToLocalStorage();
      }
    )
  }

  addDirectAccessToLocalStorage() {
    this.favoritesDirectAccessBehaviorTemp.next(this.favoritesDirectAccess.map(object => ({ ...object })));
    this.favoritesDirectAccess.forEach(
      content => {
        let favorite: Favorite<DirectAccess> = new Favorite();
        favorite.content = content;
        favorite.referenceId = content.directAccessLinks[0].linkId.toString();
        favorite.referenceType = ReferenceType.DIRECT_ACCESS;
        this.createFavorite(favorite);
      }
    )
  }

  removeDirectAccessFromLocalStorage(): void {
    let test = this.getFavoritesFromLocal().filter(fav => fav.referenceType.toString() !== ReferenceType.DIRECT_ACCESS.toString());
    this.setFavoritesToLocal(test);
  }

  refreshPage(): void {
    this.favorites = this.getFavoritesFromLocal();
    this.favoritesBehavior.next(this.favorites);
  }

  createFavorite(favorite: Favorite<any>): void {
    if (!this.favorites.find(x => x.referenceId == favorite.referenceId))
      this.favorites.push(favorite);
    this.setFavoritesToLocal(this.favorites);
    this.favoritesBehavior.next(this.favorites);
  }


  isFavorite(favorite: Favorite<any>): Favorite<any> {
    return this.getFavoritesFromLocal().find(fav => favorite.referenceId === fav.referenceId && fav.referenceType.toString() === favorite.referenceType.toString());
  }


  toggle(favorite: Favorite<any>): void {

    if (!this.isFavorite(favorite)) {
      this.addFavorite(favorite);
    }
    else {
      this.removeFavorite(favorite);
    }
  }


  addFavorite(favorite: Favorite<any>): void {

    this.addFavoriteRequest(favorite).subscribe(
      response => {
        this.favorites.push(favorite);
        this.setFavoritesToLocal(this.favorites);
        this.favoritesBehavior.next(this.favorites);
      },
      error => {

      }
    );
  }

  removeFavorite(favorite: Favorite<any>): void {

    this.deleteFavoriteRequest(favorite).subscribe(
      response => {
        let f = this.favorites.find(fav => favorite.referenceId === fav.referenceId && fav.referenceType.toString() === favorite.referenceType.toString());
        const index = this.favorites.indexOf(f);
        if (index > -1) {
          this.favorites.splice(index, 1);
          this.setFavoritesToLocal(this.favorites);

          this.favoritesBehavior.next(this.favorites);
        }
      },
      error => {

      }
    );
  }


  getFavoritesFromLocal(): Favorite<any>[] {
    return JSON.parse(sessionStorage.getItem('favorites'));
  }

  setFavoritesToLocal(favorites: Favorite<any>[]) {
    sessionStorage.setItem('favorites', JSON.stringify(favorites));
  }



  clearFavorites() {
    sessionStorage.removeItem('favorites');
    this.favorites = [];
    this.favoritesBehavior.next([]);
  }


  addToDirectAccessFavorite(directAccess: DirectAccess): void {
    this.favoritesDirectAccessTemp.push(directAccess);
    this.favoritesDirectAccessBehaviorTemp.next(this.favoritesDirectAccessTemp);
  }

  removeToDirectAccessFavorite(directAccess: DirectAccess): void {
    this.favoritesDirectAccessTemp = this.favoritesDirectAccessTemp.filter(da => !(da.directAccessLinks.map(link => link.linkId).includes(directAccess.directAccessLinks[0].linkId)));
    this.favoritesDirectAccessBehaviorTemp.next(this.favoritesDirectAccessTemp);
  }

  initTempDirectAccess(): void {
    this.favoritesDirectAccessTemp = this.favoritesDirectAccess.map(object => ({ ...object }));
    this.favoritesDirectAccessBehaviorTemp.next(this.favoritesDirectAccessTemp);
  }


}
