import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorInterceptorService {

  constructor(private router: Router) { }

  intercept(error: HttpErrorResponse): void {
    switch (error.status) {
      case 404:
      case 400:
      case 405:
        this.router.navigateByUrl('/page-404');
        break;
      case 500:
        this.router.navigateByUrl('/page-500');
        return console.log(error);
      default:
        return console.log(error);
    }
  }
}
