import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[routerLink]', // Same selector as Angular's RouterLink
})
export class CustomRouterLinkDirective {
  @Input() routerLink!: string | any[];

  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
  
    // Retrieve the language from local storage
    const selectedLanguage = localStorage.getItem('selectedlanguage') || 'fr'; // Default to 'fr' if not set
    console.log('Selected language:', selectedLanguage);
  
    // Prepend the language to the URL
    let updatedLink: string | any[];
    if (Array.isArray(this.routerLink)) {
      // Ensure the language is added as the first segment
      updatedLink = [`/${selectedLanguage}${this.routerLink[0]}`];
    } else {
      // Ensure the string starts with a slash and prepend the language
      updatedLink = `/${selectedLanguage}${this.routerLink.startsWith('/') ? '' : '/'}${this.routerLink}`;
    }
  
    console.log('Custom RouterLink clicked:', updatedLink);
  
    // Navigate to the updated URL
    this.router.navigate(Array.isArray(updatedLink) ? updatedLink : [updatedLink]);
  }
}