import { Component, Input } from '@angular/core';
import { BftProvider, BftService, ProviderContent } from 'src/app/models/bftlab/bftprovider.model';

@Component({
  selector: 'dep-card-structure',
  templateUrl: './card-structure.component.html',
  styleUrls: ['./card-structure.component.scss']
})
export class CardStructureComponent {
  @Input() structure: BftProvider;
  @Input() typeFilter: number[];
  @Input() formatFilter: number[];
  @Input() regionFilter: number[];
  

  isEmail : boolean;
  clipboardCopySuccess = false;
  clipboardmessageDuration = 500;
  isListOpen = false;

  regionTags: ProviderContent[] = [];
  typeTags: ProviderContent[] = [];
  prestations: BftService[] = [];

  ngOnInit(): void {

    this.regionTags = this.regionFilter && this.regionFilter.length>0 
      ? this.structure.regions.filter(region => this.regionFilter.includes(region.id)) 
      : this.structure.regions;

    this.structure.bftServices.forEach( service => {
      service.bftTypeOfServices.forEach( type => {
        if(!this.typeTags.map(type => type.id).includes(type.id)&&(!this.typeFilter || this.typeFilter.length == 0 || this.typeFilter.includes(type.id))){
          this.typeTags.push(type);
        }
      })
    })

    this.prestations = this.structure.bftServices.sort((a, b) => {
      return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime();
    });

  }

  public copyText(text : string , isEmail : boolean): void {
    this.isEmail = isEmail;
    navigator.clipboard.writeText(text)
      .then(() => {
        this.clipboardCopySuccess = true;
        setTimeout(() => {
          this.clipboardCopySuccess = false;
        }, this.clipboardmessageDuration);
      })
      .catch(() => this.clipboardCopySuccess = false);
  }

  toggleList() {
    this.isListOpen = !this.isListOpen;
  }
}
