import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock';
import { filter } from 'rxjs/operators';
import { User } from 'src/app/models/user/user';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/authentification/auth.service';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'dep-navbar-partner-space',
  templateUrl: './navbar-partner-space.component.html',
  styleUrls: ['./navbar-partner-space.component.scss']
})
export class NavbarPartnerSpaceComponent implements OnInit, OnDestroy, AfterViewInit {
  menuIsOpen: boolean = false;
  selectedItem: string = '';
  mainNav = null;
  isProfil: boolean = false;
  myCommunityURL = environment.myCommunityURL;
  innerWidth: number;
  isMobile: boolean = false;
  user!: User;
  menuStates: { [key: string]: boolean } = {};
  activeParentItem: string;
  eventType: string;
  backOfficeUrl: string;
  
  isTooltip : boolean = false;
  initials:string="";


  constructor(
    router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.isProfil = window.location.href.endsWith("/profil.html");
      }
      if (event instanceof NavigationEnd) {
        this.isProfil = window.location.href.endsWith("/profil.html");
      }
    });

    route.queryParams.subscribe(params => {
      this.getSelectedItem(params['type']);
    });

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.closeMenu();
      // active route
      const currentUrl = router.url;
      if (currentUrl.includes('vivier-de-temoins/') || currentUrl.includes('deeptech-campus/') || currentUrl.includes('pass-crea/') ) {
        this.activeParentItem = 'sensibiliser';
      } else if (currentUrl.includes('tandem/') || currentUrl.includes('tango/') || currentUrl.includes('communaute-de-talents/')) {
        this.activeParentItem = 'recruter';
      } else if (currentUrl.includes('bourse-french-tech-lab/') || currentUrl.includes('bftlab-prestataires/') || currentUrl.includes('euroquity/')) {
        this.activeParentItem = 'financer';
      } else if (currentUrl.includes('calendrier-des-evenements/') || currentUrl.includes('les-actualites/') || currentUrl.includes('evenements-actualites-form/')) {
        this.activeParentItem = 'chiffres-et-actualités';
      } else {
        this.activeParentItem = '';
      }
    });
    this.backOfficeUrl = environment.backOfficeUrl;
  }

  ngOnInit(): void {
    this.authService.userSubject.subscribe(
      response => {
        this.user = response;
        this.initials=this.sharedService.getInitials(response);
      }
    );
    this.sharedService.eventTypeSubject.subscribe(
      result => {
        this.eventType = result;
      }
    )
    this.detectWindowSize();
  }

  ngAfterViewInit(): void {
    this.mainNav = document.querySelector('#main-nav');
  }

  ngOnDestroy(): void {
    clearAllBodyScrollLocks();
  }

  toggleMenu(item: string): void {
    this.menuStates[item] = !this.menuStates[item];

    Object.keys(this.menuStates).forEach(key => {
      if (key !== item) {
        this.menuStates[key] = false;
      }
    })
  }

  login() {
    this.authService.login();
  }

  closeMenu(): void {
    Object.keys(this.menuStates).forEach(key => {
      this.menuStates[key] = false;
    });

    // Re-enable body scroll
    enableBodyScroll(this.mainNav);
  }

  logout() {
    this.authService.logout();
  }

  getSelectedItem(item: string): void {
    this.selectedItem = item;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.detectWindowSize();
  }

  detectWindowSize(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1258) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  };

  showTooltip() : void {
    this.isTooltip = true;
  }
  
  hideTooltip() : void {
    this.isTooltip = false;
  }
}