import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GuidedTourService } from 'src/app/services/guided-tour/guided-tour.service';
import { AuthService } from 'src/app/shared/services/authentification/auth.service';

@Component({
  selector: 'dep-guided-tour-popup',
  templateUrl: './guided-tour-popup.component.html',
  styleUrls: ['./guided-tour-popup.component.scss']
})
export class GuidedTourPopupComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() stepId: number;
  @Input() libelleBtn : string;
  @Input() trianglePosition: 'top-center' | 'left-bottom' | 'top-right' = 'top-center';
  public showPopup : boolean = true;
  
  constructor(private guidedTourService: GuidedTourService) { }

  ngOnInit() {
    this.showPopup = (this.guidedTourService.getCurrentStep() === this.stepId);

    this.guidedTourService.stepChanged.subscribe((step: number) => {
      this.showPopup = (step === this.stepId);
    });
  }

  next() {
    this.guidedTourService.nextStep();
  }

  closePopup() {
    this.guidedTourService.closePopUp();
    this.showPopup = false;
  }
}
