import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Favorite } from 'src/app/models/favorites/favorite.model';
import { ReferenceType } from 'src/app/models/favorites/referenceType';
import { User } from 'src/app/models/user/user';
import { ContactsService } from 'src/app/services/contacts/contacts.service';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';

@Component({
  selector: 'dep-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @Input() contact: User;

  isSelected: boolean = false;
  isEmail : boolean = false;
  photo:string;
  clipboardCopySuccess = false;
  clipboardmessageDuration = 500;
  
  favorite:Favorite<any> = new Favorite();
  isChecked: boolean = true;
  @ViewChild('contactContainer') contactContainer: ElementRef;
  containerWidth: number;
  initials:string="";

  public favoriteContact$ = this.favoriteService.favorites$.pipe(
    map(features => features.filter(feature => feature.referenceType.toString() == ReferenceType.CONTACT.toString()))
  );

  constructor(
    private contactsService: ContactsService,
    private favoriteService: FavoriteService,
    private sharedService:SharedService
  ) { }

  ngAfterViewInit() {
    this.containerWidth = this.contactContainer.nativeElement.offsetWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.containerWidth = this.contactContainer.nativeElement.offsetWidth;
  }

  ngOnInit(): void {
    if (this.contact && this.contact.photoUrl) {
      this.photo = this.contact.photoUrl;
    }

    this.initials=this.sharedService.getInitials(this.contact)
    this.favorite.content = this.contact;
    this.favorite.referenceId = this.contact.id;
    this.favorite.referenceType = ReferenceType.CONTACT;

    this.contactsService.contactSelected$.subscribe(
      response => {
        if(response!=null){
          this.isSelected = response.id === this.contact.id;
        }
        else{
          this.isSelected = false;
        }
      }
    )


    this.favoriteContact$.subscribe(
      response => {
        this.isChecked = response.find(fav=>fav.referenceId === this.contact.id)!=null;
      }
    )


  }

  showDetails(): void {
    this.contactsService.contactSelectedSubject$.next(this.contact);
  }

  closeDetails(): void {
    this.contactsService.contactSelectedSubject$.next(null);
  }

  public copyText(text : string , isEmail : boolean): void {
    this.isEmail = isEmail;
    navigator.clipboard.writeText(text)
      .then(() => {
        this.clipboardCopySuccess = true;
        setTimeout(() => {
          this.clipboardCopySuccess = false;
        }, this.clipboardmessageDuration);
      })
      .catch(() => this.clipboardCopySuccess = false);
  }

  toggle(): void{
    this.favoriteService.toggle(this.favorite);    
  }

}
