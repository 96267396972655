<section class="page-header">
  <div class="page-header__container">
    <div class="page-header__content">
      <button type="button" class="page-header__back" area-label="back button" (click)="back()">
        <dep-icon class="icon" [icon]="'icon-arrow_back_ios'"></dep-icon>
        Retour
      </button>

      <div class="page-header__breadcrumb">
        <ng-content select="[breadcrumb]"></ng-content>
      </div>

      <h1 class="page-header__title">Actualités des acteurs<br/>de la deeptech</h1>
      <nav aria-label="Menu secondaire">
        <ul class="page-header__links">
          <li *ngFor="let actualitiesType of actualitiesTypes;let i=index">
            <a
              class="page-header__link"
              [routerLink]="['/' + actualitiesType?.route ]"
              [routerLinkActive]="'is-active'"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              #rla="routerLinkActive"
            >
              <dep-icon [icon]="actualitiesType.icon" class="icon"></dep-icon>
              <span>{{actualitiesType?.name}}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>

  </div>
</section>
