<dep-main-header></dep-main-header>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Concevoir son business plan">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup'" *appBreadcrumbItem>Envie
        d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Concevoir son business plan</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>

  <!-- Présenter son projet avec le business plan -->
  <dep-page-section sectionTitle="Présenter son projet avec le business plan" sectionTheme="medium">
    <article class="article">
      <dep-text>
        <p>
          Le business plan est un document qui présente de manière complète votre projet de création d’entreprise. Il
          vous permet de structurer, challenger et piloter votre projet.
        </p>
        <p>
          C’est également un document clé qui doit vous permettre de convaincre des investisseurs du potentiel de
          croissance de votre entreprise.
        </p>
        <p>
          Les 3 objectifs d’un business plan sont :
        </p>
        <ol>
          <li>
            Positionner clairement la proposition de valeur de votre entreprise
          </li>
          <li>
            Mettre en valeur les membres de l’équipe fondatrice
          </li>
          <li>
            Démontrer la performance financière future de votre entreprise (qui servira également de base à la première
            valorisation de votre startup).
          </li>
        </ol>
      </dep-text>
    </article>
  </dep-page-section>
  <!-- FIN de Présenter son projet avec le business plan -->

  <!-- Contenu éditorial -->
  <dep-page-section>
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Les 4 composantes courantes dans un business plan</h2>

          <p>Chaque business plan est unique car il dépend de la nature de l’entreprise, de ses produits et services,
            des marchés sur lesquels elle souhaite s’implanter, etc… On retrouve néanmoins 4 sections dans un business
            plan :</p>

          <h3><span class="yellow-text">1.</span> Executive summary</h3>

          <p>Il s’agit d’une présentation en une page de votre entreprise qui doit convaincre votre interlocuteur de
            vouloir en savoir plus sur votre projet (ex : décrocher un rendez-vous avec un investisseur).</p>

          <h3><span class="yellow-text">2.</span> Présentation de l’équipe</h3>

          <p>Un investisseur investit généralement autant sur l’équipe que sur le projet d’entreprise. Cette section
            doit mettre en évidence la légitimité de l’équipe et sa capacité à porter le projet (complémentarité des
            profils, compétences spécifiques, expériences passées, …).</p>

          <h3><span class="yellow-text">3.</span> Potentiel de l’entreprise et ressources clés</h3>

          <p>Il s’agit de formaliser les résultats de la maturation technico-économique de votre projet (<a
              href="https://transfertostartup.com/" target="_blank" rel="noopener noreferrer">voir composante 5</a>).
            Votre interlocuteur doit comprendre votre position sur les marchés ciblés, le business model de votre
            entreprise, sa stratégie d’accès au marché ainsi que les ressources clés mises en œuvre pour appliquer cette
            stratégie.</p>

          <h3><span class="yellow-text">4.</span> Un prévisionnel financier</h3>

          <p>Le prévisionnel est la traduction financière des hypothèses économiques présentées dans la section n°3 sur
            une durée de 5 ans. C’est une section complexe à compléter dans laquelle on peut retrouver 4 parties :</p>

          <ul>
            <li>
              Compte de résultat : détail des charges et produits comptables par année et formation du résultat
            </li>
            <li>
              Plan de trésorerie : projection mensuelle de toutes les entrées et sorties d’argent.
            </li>
            <li>
              Plan de financement : besoins financiers de l’entreprise année par année
            </li>
            <li>
              Bilan : situation patrimoniale de l’entreprise avec l’actif (les emplois) et le passif (les ressources)
            </li>
          </ul>

          <p>Le prévisionnel financier doit être cohérent avec votre business model et votre stratégie. Il doit vous
            permettre de tester différentes hypothèses de marché pour créer des scenarii alternatifs de croissance
            (optimistes/pessimistes) et d’en voir les impacts sur votre projet. Son objectif est de démontrer la
            rentabilité de votre projet.</p>

          <p>Au cours de vos négociations avec des investisseurs, vos hypothèses seront challengées. La robustesse de
            votre business plan déterminera in fine la valorisation de votre entreprise dont découleront les
            propositions de prises de participations de vos investisseurs.</p>
        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-resource resourceTitle="Template de BP" resource="/assets/docs/fisy-innovation.xlsx">
            <p>Un modèle créé par FISY pour les projets à forte composante de R&D.</p>

            <p>Ce type de prévisionnel financier peut faire peur au premier abord. Il n’y a pas de recette miracle pour
              le remplir : cela demande du temps et de l’énergie. Vous pouvez néanmoins demander de l’aide à
              l’écosystème qui accompagne votre projet </p>
          </dep-resource>
        </aside>
      </div>
    </article>
  </dep-page-section>
  <!-- FIN de Contenu éditorial -->

  <!-- Les parties prenantes clés -->
  <dep-page-section sectionTitle="Les parties prenantes clés" sectionTheme="medium">
    <div class="grid-container">
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        Les porteurs de projet
      </dep-icon-text>
    </div>
  </dep-page-section>
  <!-- FIN de Les parties prenantes clés -->

  <!-- Les autres composantes -->
  <dep-page-section sectionTitle="Les autres composantes">
    <div class="grid-container">
      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/acces-au-marche.html']">Dérisquage
          technico-économique du projet</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/transfert-de-technologies.html']">Créer la startup et intégrer la
          PI</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/financer-son-projet.html']">Financer son projet</a></dep-step>

    </div>
  </dep-page-section>
  <!-- FIN de Les parties prenantes clés -->
</main>

<dep-main-footer></dep-main-footer>