<header class="main-header" *ngIf="!isMobile">
  <!-- Logo, link to homepage -->
  <section class="main-header__logo">
    <a routerLink="/" [routerLinkActive]="'is-active'" [routerLinkActiveOptions]="{exact:true}"
      (click)="getSelectedItem(''); closeMenu()" aria-label="Accueil" ariaCurrentPage>
      <img src="/assets/images/deep-tech/Logo-Lesdeeptech-bleu.png" alt="Les deeptech">
    </a>
  </section>
  <span class="separator customised"></span>
  <!-- main navigation -->
  <nav id="main-nav" class="main-nav" aria-label="Menu principal" (clickOutside)="getSelectedItem('');closeMenu()">
    <ul class="main-nav__level-1">
      <li class="main-nav__level-1-item">
        <a class="main-nav__level-1-link" [routerLink]="['/mon-espace-partenaire']" (click)="getSelectedItem('')">
          <span class="customised partner-space" style="font-weight: 700;padding-left: 0;">
            <dep-icon [icon]="'icon-home'" class="icon-home"></dep-icon>
            Espace partenaires
          </span>
        </a>
      </li>
      <!-- sensibiliser -->
      <li class="main-nav__level-1-item"  [routerLinkActive]="'is-active'">
        <button class="main-nav__button main-nav__level-1-link" aria-controls="sensibiliser"
          (click)="getSelectedItem('sensibiliser');toggleMenu('sensibiliser');">
          <span class="customised"  [class.is-active-parent]="activeParentItem === 'sensibiliser'">Sensibiliser
            <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
              [class.rotate]="menuStates['sensibiliser'] && selectedItem == 'sensibiliser'"></dep-icon>
          </span>
        </button>
      </li>
      <div class="main-nav__sub-menu customised" *ngIf="menuStates['sensibiliser']" [class.visible]="selectedItem == 'sensibiliser'">
        <ul>
          <li>
            <p>Vivier de témoins</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/vivier-de-temoins']"
                  (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Trouver un intervenant</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p>Deeptech Campus</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/deeptech-campus']" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Accéder au Deeptech Campus</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Recruter -->
      <li class="main-nav__level-1-item"  [routerLinkActive]="'is-active'">
        <button class="main-nav__button main-nav__level-1-link" aria-controls="recruter"
          (click)="getSelectedItem('recruter');toggleMenu('recruter');">
          <span class="customised"  [class.is-active-parent]="activeParentItem === 'recruter'">Recruter
            <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
              [class.rotate]="menuStates['recruter'] && selectedItem == 'recruter'"></dep-icon>
          </span>
        </button>
      </li>
      <div class="main-nav__sub-menu customised" *ngIf="menuStates['recruter']" [class.visible]="selectedItem == 'recruter'">
        <ul class="sub-menu-2">
          <li>
            <p>Tango</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/tango']" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Recruter un advisor</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p>Tandem</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/tandem']" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Recruter un associé</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p>Communauté de talents</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/communaute-de-talents']" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Rejoindre la communauté</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Financer -->
      <li class="main-nav__level-1-item"  [routerLinkActive]="'is-active'">
        <button class="main-nav__button main-nav__level-1-link" aria-controls="financer"
          (click)="getSelectedItem('financer');toggleMenu('financer');">
          <span class="customised"  [class.is-active-parent]="activeParentItem === 'financer'">Financer
            <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
              [class.rotate]="menuStates['financer'] && selectedItem == 'financer'"></dep-icon>
          </span>
        </button>
      </li>
      <div class="main-nav__sub-menu customised" *ngIf="menuStates['financer']" [class.visible]="selectedItem == 'financer'">
        <ul class="sub-menu-3">
          <li>
            <p>BFT Lab</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/bourse-french-tech-lab']" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Trouver un financement</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p>Euroquity</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'" *featureToggle="'enableEuroquity'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/euroquity']" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Découvrez la communauté</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Mes chiffres & actualités -->
      <li class="main-nav__level-1-item">
        <button class="main-nav__button main-nav__level-1-link" aria-controls="mes chiffres et actualités"
          (click)="getSelectedItem('chiffres-et-actualités');toggleMenu('chiffres-et-actualités');">
          <span class="customised" [class.is-active-parent]="activeParentItem === 'chiffres-et-actualités'">Actualités
            <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
              [class.rotate]="menuStates['chiffres-et-actualités'] && selectedItem == 'chiffres-et-actualités'"></dep-icon>
          </span>
        </button>
      </li>
      <div class="main-nav__sub-menu customised" *ngIf="menuStates['chiffres-et-actualités']"
        [class.visible]="selectedItem == 'chiffres-et-actualités'">
        <ul class="sub-menu-4">
          <li *featureToggle="'enableMenuItems'">
            <p>Dynamique startups</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Observatoire partenaires</span>
                </a>
              </li>
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Les dernières Startups</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p>Ressources</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/les-actualites']" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Actualités</span>
                </a>
              </li>
              <li class="main-nav__level-1-item" id="actuality"  [ngClass]="{'is-active':eventType==='actuality'}">
                <a class="main-nav__level-1-link level-2"
                  [routerLink]="['/mon-espace-partenaire/evenements-actualites-form']" 
                  [queryParams]="{ type: 'actuality' }"
                  (click)="getSelectedItem('')">
                  <span class="customised">Publier une actualité</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p>Agenda</p>
            <ul class="main-nav__level-2">
              <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/calendrier-des-evenements']" (click)="getSelectedItem('')" ariaCurrentPage>
                  <span class="customised">Calendrier des évènements</span>
                </a>
              </li>
              <li class="main-nav__level-1-item" id="event"  [ngClass]="{'is-active':eventType==='event'}">
                <a class="main-nav__level-1-link level-2"
                  [routerLink]="['/mon-espace-partenaire/evenements-actualites-form']" 
                  [queryParams]="{ type: 'event' }"
                  (click)="getSelectedItem('')">
                  <span class="customised">Publier un évènement</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'" *featureToggle="'enablePartnerServices'">
        <a class="main-nav__level-1-link" [routerLink]="['/mon-espace-partenaire/les-services.html']"
          (click)="getSelectedItem(''); closeMenu()" ariaCurrentPage>
          <span class="customised">Services</span>
        </a>
      </li>
      <ng-container *featureToggle="'enablePartnerContacts'">
        <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'" *ngIf="showContact">
          <a class="main-nav__level-1-link" [routerLink]="['/mon-espace-partenaire/mes-contacts']" (click)="getSelectedItem(''); closeMenu()" ariaCurrentPage>
            <span class="customised">Annuaire des partenaires</span>
          </a>
        </li>
      </ng-container>
      <!-- Profil -->
      <ul class="main-nav__level-1 profil_nav" *ngIf="user?.id">
        <div class="profil_name">
          <ng-container *ngIf="isProfil">
            <p><strong>{{ user?.firstName | titlecase }} {{ user?.lastName | uppercase }}</strong>
            </p>
          </ng-container>
          <ng-container *ngIf="!isProfil">
            <p>{{ user?.firstName | titlecase }} {{ user?.lastName | uppercase }}</p>
          </ng-container>
        </div>
        <li class="main-nav__level-1-item">
          <button class="profil-button" [class.is-expanded]="selectedItem == 'profil'"
            [attr.aria-expanded]="selectedItem == 'profil' ? true : false" aria-controls="profil_level-2"
            (click)="getSelectedItem('profil');toggleMenu('profil');">
            <span>
              <img class="profil customised" src="/assets/images/espace-partenaire/icon-profil.png" alt="profil icon" />
            </span>
          </button>

          <!-- Second level of navigation -->
          <ul id="profil_level-2" class="main-nav__level-2 " [class.is-visible]="selectedItem == 'profil'"
          *ngIf="menuStates['profil']">
            <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
              <a class="main-nav__profil" [routerLink]="['/mon-espace-partenaire/profil.html']"
                (click)="getSelectedItem(''); closeMenu()" ariaCurrentPage>
                <span class="customised">Voir mon profil
                  <dep-icon [icon]="'icon-profil'" class="icon"></dep-icon>
                </span>
              </a>
            </li>
            <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
              <a class="main-nav__profil" href="https://backoffice.dev.dep-nprd.cloud.bpifrance.fr"
                (click)="getSelectedItem(''); closeMenu()" target="_blank" rel="noopener noreferrer" ariaCurrentPage>
                <span class="customised">Administration
                  <dep-icon [icon]="'icon-table'" class="icon"></dep-icon>
                </span>
              </a>
            </li>
            <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
              <a class="main-nav__profil" (click)="logout(); getSelectedItem(''); closeMenu()" ariaCurrentPage>
                <span class="customised">Me déconnecter
                  <dep-icon [icon]="'icon-loging-out'" class="icon"></dep-icon>
                </span>
              </a>
            </li>
          </ul>
          <!-- END of second level of navigation -->
        </li>
      </ul>
    </ul>

  </nav>
</header>

<!---------------------- header Mobile ---------------------------->
<header class="main-header__mobile" *ngIf="isMobile">
  <nav class="main-header__mobile-container" (clickOutside)="getSelectedItem('');closeMenu()">
    <div class="main-nav__mobile">
      <button class="menu-button" [class.is-expanded]="selectedItem == 'menu-mobile'"
        [attr.aria-expanded]="selectedItem == 'menu-mobile' ? true : false" aria-controls="profil_level-2"
        (click)="getSelectedItem('menu-mobile');toggleMenu('menu-mobile');">
        <dep-icon [icon]="'icon-menu-mobile'" class="icon-menu" *ngIf="!menuStates['menu-mobile']"></dep-icon>
        <dep-icon [icon]="'icon-close'" class="icon-menu" *ngIf="menuStates['menu-mobile']"></dep-icon>
      </button>
      <a class="main-nav__link" [routerLink]="['/mon-espace-partenaire']">
        <span class="customised" style="font-weight: 700;display: flex;align-items: center;">
          <dep-icon [icon]="'icon-home'" class="icon-home"></dep-icon>
          Espace partenaires</span>
      </a>
    </div>
    <button class="profil-button" [class.is-expanded]="selectedItem == 'profil'"
      [attr.aria-expanded]="selectedItem == 'profil' ? true : false" aria-controls="profil_level-2"
      (click)="getSelectedItem('profil');toggleMenu('profil');">
      <span>
        <img class="profil customised" src="/assets/images/espace-partenaire/icon-profil.png" alt="profil icon" />
      </span>
    </button>
    <!-- submenu mobile -->
    <div class="submenu-mobile" [class.show]="selectedItem == 'menu-mobile'" *ngIf="menuStates['menu-mobile']">
      <ul>
        <!-- Sensibiliser -->
        <li>
          <dep-collapse-menu [title]="'Sensibiliser'" [selector]="'.sensibiliser'" [isFocus]="true"></dep-collapse-menu>
          <ul class="submenu-level-2 sensibiliser">
            <li>
              <dep-collapse-menu [title]="'Vivier de témoins'" [selector]="'.temoins'"></dep-collapse-menu>
              <ul class="submenu-level-3 temoins">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/vivier-de-temoins']" (click)="getSelectedItem('')"
                    ariaCurrentPage>
                    <span class="customised">Trouver un intervenant</span>
                  </a>
                </li>
              </ul>
              <dep-collapse-menu [title]="'Deeptech campus'" [selector]="'.campus'"></dep-collapse-menu>
              <ul class="submenu-level-3 campus">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/deeptech-campus']" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Accéder au Deeptech Campus</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <!-- Recruter -->
        <li>
          <dep-collapse-menu [title]="'Recruter'" [selector]="'.recruter'" [isFocus]="true"></dep-collapse-menu>
          <ul class="submenu-level-2 recruter">
            <li>
              <dep-collapse-menu [title]="'Tango'" [selector]="'.tango'"></dep-collapse-menu>
              <ul class="submenu-level-3 tango">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/tango']" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Recruter un advisor</span>
                  </a>
                </li>
              </ul>
              <dep-collapse-menu [title]="'Tandem'" [selector]="'.tandem'"></dep-collapse-menu>
              <ul class="submenu-level-3 tandem">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/tandem']" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Recruter un associé</span>
                  </a>
                </li>
              </ul>
              <dep-collapse-menu [title]="'Communauté de talents'" [selector]="'.talents'"></dep-collapse-menu>
              <ul class="submenu-level-3 talents">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/communaute-de-talents']" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Rejoindre la communauté</span>
                  </a>
                </li>
              </ul>
            </li> 
            </ul> 
          </li>
         <!-- Financer -->
         <li>
          <dep-collapse-menu [title]="'Financer'" [selector]="'.financer'" [isFocus]="true"></dep-collapse-menu>
          <ul class="submenu-level-2 financer">
            <li>
              <dep-collapse-menu [title]="'BFT Lab'" [selector]="'.bft-lab'"></dep-collapse-menu>
              <ul class="submenu-level-3 bft-lab">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/bourse-french-tech-lab']" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Trouver un financement</span>
                  </a>
                </li>
              </ul>
              <dep-collapse-menu [title]="'Euroquity'" [selector]="'.euroquity'"></dep-collapse-menu>
              <ul class="submenu-level-3 euroquity">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/euroquity']" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Découvrez la communauté</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <!-- Mes chiffres & actualités -->
        <li>
          <dep-collapse-menu [title]="'Actualités'" [selector]="'.mes-chiffres'"
            [isFocus]="true"></dep-collapse-menu>
          <ul class="submenu-level-2 mes-chiffres">
            <li *featureToggle="'enableMenuItems'">
              <dep-collapse-menu [title]="'Dynamique startups'" [selector]="'.startups'"></dep-collapse-menu>
              <ul class="submenu-level-3 startups">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Observatoire partenaires</span>
                  </a>
                </li>
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Les dernières Startups</span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <dep-collapse-menu [title]="'Ressources'" [selector]="'.ressources'"></dep-collapse-menu>
              <ul class="submenu-level-3 ressources">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/les-actualites']" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Actualités</span>
                  </a>
                </li>
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/evenements-actualites-form']" 
                  [queryParams]="{ type: 'actuality' }"
                  [state]="{source:'actuality'}"
                  (click)="getSelectedItem('')"
                    ariaCurrentPage>
                    <span class="customised">Publier une actualité</span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <dep-collapse-menu [title]="'Agenda'" [selector]="'.agenda'"></dep-collapse-menu>
              <ul class="submenu-level-3 agenda">
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/calendrier-des-evenements']" (click)="getSelectedItem('')" ariaCurrentPage>
                    <span class="customised">Calendrier des évènements</span>
                  </a>
                </li>
                <li [routerLinkActive]="'is-active'">
                  <a [routerLink]="['/mon-espace-partenaire/evenements-actualites-form']" 
                  [queryParams]="{ type: 'event' }"
                  [state]="{source:'event'}"
                  (click)="getSelectedItem('')"
                    ariaCurrentPage>
                    <span class="customised">Publier un évènement</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="item-menu" [routerLinkActive]="'is-active'">
          <a [routerLink]="['/mon-espace-partenaire/les-services.html']" (click)="getSelectedItem('')" ariaCurrentPage>
            <span class="customised">Services</span>
          </a>
        </li>
        <ng-container *featureToggle="'enablePartnerContacts'">
          <li class="item-menu" [routerLinkActive]="'is-active'" *ngIf="showContact">
            <a  [routerLink]="['/mon-espace-partenaire/mes-contacts']" (click)="getSelectedItem('')" ariaCurrentPage>
              <span>Annuaire des partenaires</span>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>

    <!-- submenu profil settings -->
    <div class="profil-settings" [class.show]="selectedItem == 'profil'" *ngIf="menuStates['profil']">
      <ul>
        <li [routerLinkActive]="'is-active'">
          <a class="profil-settings__item" [routerLink]="['/mon-espace-partenaire/profil.html']"
            (click)="getSelectedItem(''); closeMenu()" ariaCurrentPage>
            <span>Voir mon profil
              <dep-icon [icon]="'icon-profil'" class="icon"></dep-icon>
            </span>
          </a>
        </li>
        <li [routerLinkActive]="'is-active'">
          <a class="profil-settings__item" [routerLink]="['/mon-espace-partenaire/profil.html']"
            (click)="getSelectedItem(''); closeMenu()" target="_blank" rel="noopener noreferrer" ariaCurrentPage>
            <span>Administration
              <dep-icon [icon]="'icon-table'" class="icon"></dep-icon>
            </span>
          </a>
        </li>
        <li [routerLinkActive]="'is-active'">
          <a class="profil-settings__item" (click)="logout(); getSelectedItem(''); closeMenu()" ariaCurrentPage>
            <span>Me déconnecter
              <dep-icon [icon]="'icon-loging-out'" class="icon"></dep-icon>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>