<div class="video__container" [routerLink]="replaceUrl()" >
    <div class="video__image">
        <div class="video__image-wrapper">
            <img src="{{video.imageUrl}}" alt="{{video.altImage}}" class="customised img-video" >
        </div>
    </div>
    <div class="video__header">
        <p>{{video.tags[0].name}}</p>
        <p class="video__duration">{{video.duration}}
            <img src="/assets/images/icones/timer.png" alt="icon-timer" class="customised" />
        </p>
    </div>
    <p class="video__title">{{video.title}}</p>
    <p class="video__description">{{video.description}}</p>
    <a [routerLink]="replaceUrl()" class="video__link">Visionner 
        <dep-icon [icon]="'icon-call_made'" class="icon-size"></dep-icon>
    </a>
    <div class="video__button">
        <button class="ds-btn ds-btn--secondary" [routerLink]="'/videos'">
            Voir toutes les autres vidéos
          <dep-icon [icon]="'icon-movie'" class="icon-size"></dep-icon>
        </button>
    </div>
</div>
