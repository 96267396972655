<dep-main-header></dep-main-header>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Protéger une invention">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup/.'" *appBreadcrumbItem>Envie
        d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Protéger une invention</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>

  <!-- La protection de la propriété intellectuelle -->
  <dep-page-section sectionTitle="La protection de la propriété intellectuelle" sectionTheme="medium">
    <dep-text>
      <p>
        La propriété intellectuelle est un actif majeur d’une startup deeptech. Bien protéger son invention est
        déterminant pour que l’entreprise bénéficie d’une barrière à l’entrée forte vis-à-vis de ses concurrents qu’elle
        pourra valoriser auprès de ses futurs clients et investisseurs.
      </p>
      <p>
        A ce stade du projet, la propriété intellectuelle appartient à l’établissement de recherche. C’est la structure
        mandatée par l’établissement pour sa valorisation qui va mettre en place et financer la stratégie adéquate de
        protection.
      </p>
      <p>
        Les chercheurs ayant contribué à la recherche sont identifiés comme inventeurs de la PI mais ne possèdent pas
        (encore) le droit de l’exploiter. Le droit d’exploitation de la PI sera négocié entre la structure de
        valorisation et la future startup dans le termsheet puis dans le contrat de licence.
      </p>
      <p>
        En raison de sa position, la structure de valorisation est donc l’interlocuteur clé d’un entrepreneur en quête
        de technologies innovantes pour répondre à un besoin marché identifié. Pour en découvrir un premier aperçu,
        <strong><a href="https://www.lesinnopreneurs.com/page/concept-bourse-aux-projets" target="_blank"
            rel="noopener noreferrer">rendez-vous ici</a></strong> !
      </p>
    </dep-text>
  </dep-page-section>
  <!-- FIN de La protection de la propriété intellectuelle -->

  <!-- Contenu éditorial -->
  <dep-page-section>
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Le brevet d’invention</h2>

          <p>
            Le brevet d’invention <strong>protège une invention technique</strong>, c’est-à-dire un produit ou un
            procédé qui apporte une nouvelle solution technique à un problème technique donné. Il est délivré par un
            office de propriété intellectuelle: l’INPI en France, l’OEB en Europe.
          </p>
          <p>
            Il confère à son titulaire un droit d’interdire à quiconque n’est pas autorisé, de copier, distribuer,
            utiliser et exploiter à des fins commerciales l’invention en question <strong>pendant une période pouvant
              aller jusqu’à 20 ans et sur une zone géographique donnée</strong>.
          </p>

          <h3>Condition de brevetabilité</h3>

          <p>
            Votre invention est brevetable si, et seulement si, elle remplit les 3 conditions suivantes :
          </p>
          <ol>
            <li><strong>Être nouvelle :</strong> votre invention doit posséder une caractéristique totalement inédite et
              ne doit pas être comprise dans l’état de la technique.</li>
            <li><strong>Être inventive :</strong> elle ne doit pas découler de manière évidente de l’état de la
              technique (invention de rupture et non incrémentale).</li>
            <li><strong> faire l’objet d’une application industrielle :</strong> l’invention doit pouvoir être fabriquée
              ou utilisée industriellement.</li>
          </ol>

        </div>
        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-idea ideaTitle="Une erreur à éviter" ideaAlignOffset="medium">
            Toute divulgation antérieure annule le caractère de nouveauté, exigé par la brevetabilité.<br />
            Sont à proscrire : toute présentation lors d’un séminaire, publication dans une revue scientifique, poster,
            soutenance de thèse, communication à des confrères qui ne seraient pas sous contrat de
            confidentialité.<br />
            Pour continuer de communiquer sans risque, nous conseillons à tout chercheur de se rapprocher de son service
            de valorisation référent !
          </dep-idea>
        </aside>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">

          <h3>Les points clés à aborder</h3>
          <ul class="un">
            <li>
              Recherche approfondie dans les bases de données de brevets et s’assurer de ne pas réinventer ce qui existe
              déjà
            </li>
            <li>
              Se faire aider d’un professionnel de la PI pour la rédaction de la demande
            </li>
            <li>
              Choisir judicieusement le moment du dépôt de brevet. En effet, chaque demande de brevet est publiée 18
              mois après son dépôt au Bulletin officiel de la propriété industrielle (BOPI)
            </li>
            <li>
              Identifier les pays ciblés pour la protection au regard de sa stratégie commerciale. Une demande de brevet
              constitue un investissement et la question de l’exploitation commerciale de l’invention est déjà cruciale
              à cette étape.
            </li>
          </ul>
        </div>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">

          <h2>Les autres types de protection</h2>

          <h3>Savoir-faire et confidentialité</h3>

          <p>
            Le savoir-faire d’une entreprise recouvre les procédés, formules de fabrication, secrets de fabrique,
            méthodes de gestion, innovations de toutes sortes, brevetables ou non, qui sont transmissibles mais pas
            publics. La meilleure protection d’un savoir-faire est sans aucun doute la <strong>confidentialité.</strong>
          </p>
          <p>
            La loi sanctionne en effet les actes de détournement et/ou de divulgation du savoir-faire d’autrui. Pour
            cela il faut être en mesure de prouver l’existence d’une innovation restée par définition secrète et les
            conditions dans lesquelles ce savoir-faire aurait été détourné. <strong>D’où la nécessité de mettre en place
              des mesures de traçabilité de ses innovations comme le cahier de laboratoire.</strong>
          </p>

          <h3>Numérique, Logiciel et droits d’auteurs</h3>

          <p>
            Selon l’OMPI, plusieurs éléments d’une production numérique sont susceptibles d’être protégés par de la
            propriété intellectuelle (conception site, bases de données, contenus créatifs etc…). Ces éléments tout
            comme les logiciels peuvent être protégés par le <strong>droit d’auteur.</strong>
          </p>


        </div>
      </div>

    </article>
  </dep-page-section>
  <!-- FIN de Contenu éditorial -->


  <!-- Les parties prenantes clés -->
  <dep-page-section sectionTitle="Les parties prenantes clés" sectionTheme="medium">
    <div class="grid-container">
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        Les fondateurs
      </dep-icon-text>
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        La structure de valorisation
      </dep-icon-text>
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        Les établissement de recherche publique
      </dep-icon-text>
    </div>
  </dep-page-section>
  <!-- FIN de Les parties prenantes clés -->

  <!-- Les autres composantes -->
  <dep-page-section sectionTitle="Les autres composantes">
    <div class="grid-container">
      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/entreprendre-en-equipe.html']">Monter une équipe fondatrice</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/plan-de-financement.html']">Concevoir son business plan</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/acces-au-marche.html']">Dérisquage
          technico-économique du projet</a></dep-step>
    </div>
  </dep-page-section>
  <!-- FIN de Les autres composantes -->
</main>
<dep-main-footer></dep-main-footer>