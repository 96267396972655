<dep-main-header class="main-header"></dep-main-header>

<main class="main">
  <!-- Sticky header -->
  <dep-article-sticky-header [articleTitle]="podcast.title" [articleCategories]="podcast?.tags"
    [buttonIcon]="'icon-share'" [buttonText]="'Partager'" (buttonAction)="copyUrl()" [buttonMessage]="'Lien copié !'"
    [showButtonMessage]="clipboardCopySuccess">
  </dep-article-sticky-header>

  <!-- Page header -->
  <dep-page-header-image class="page-header-wrapper" [headerImage]="podcast?.imageUrl" [altImage]="podcast?.altImage">
    <!-- Article header content -->
    <dep-article-header [articleTitle]="podcast?.title"
      [articleDate]="podcast?.publishDate | date :'d MMMM yyy' | titlecase"
      [articleDateTime]="podcast?.publishDate | date :'d MMMM yyy'" [articleReadingTime]="podcast?.duration"
      [buttonIcon]="'icon-share'" [buttonText]="'Partager'" (buttonAction)="copyUrl()" [isPopover]="true"
      [showButtonMessage]="clipboardCopySuccess" content>
      <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
        <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
        <dep-breadcrumb-item [itemRouterLink]="'/actualites-ressources'" *appBreadcrumbItem>
          Actualités et ressources
        </dep-breadcrumb-item>
        <dep-breadcrumb-item *appBreadcrumbItem>Podcasts</dep-breadcrumb-item>
      </dep-breadcrumb>
    </dep-article-header>

    <!-- Themes -->
    <dep-article-categories [articleCategories]="podcast?.tags" subheader></dep-article-categories>
  </dep-page-header-image>

  <!-- Article content -->
  <div class="article-wrapper">
    <ng-container *ngFor="let podcastContent of podcast?.contents">

      <!-- Text -->
      <ng-container *ngIf="podcastContent.textId">
        <p [innerHTML]="podcastContent.paragraph | markdown"></p>
      </ng-container>

      <!-- Quote -->
      <ng-container *ngIf="podcastContent.quoteId">
        <dep-article-quote [quote]="podcastContent.quote" [quoteSource]="podcastContent.authorName">
        </dep-article-quote>
      </ng-container>

      <!-- Related content -->
      <ng-container *ngIf="podcastContent.linkId">
        <dep-article-related-content [relatedContent]="podcastContent.linkTitle"
          [relatedLinkContent]="podcastContent.linkUrl">
        </dep-article-related-content>
      </ng-container>

      <!-- Video -->
      <ng-container *ngIf="podcastContent.videoId && podcastContent.videoUrl">
        <dep-article-video [videoUrl]="podcastContent.videoUrl" [videoType]="'video/mp4'"
          [videoTitle]="podcastContent.videoTitle" [videoSource]="podcastContent.videoSource">
        </dep-article-video>
      </ng-container>

      <!-- YOUTUBE video -->
      <!-- TODO : Allow youtube urls in inputs -->
      <ng-container *ngIf="podcastContent.videoId && podcastContent.videoSource && !podcastContent.videoUrl">
        <dep-article-youtube-video [videoUrl]="podcastContent.videoSource"
          [videoTitle]="podcastContent.videoTitle"></dep-article-youtube-video>
      </ng-container>

      <!-- Images carousel -->
      <ng-container *ngIf="podcastContent.imagesId">
        <dep-article-image-carousel [carouselTitle]="podcastContent.slideShowTitle"
          [carouselSource]="podcastContent.imagesSource">
          <ng-container *ngFor="let image of podcastContent.imagesUrls">
            <dep-article-image-carousel-item [imageSrc]="image" [imageAlt]="podcastContent.altImages"
              *appCarouselSlide></dep-article-image-carousel-item>
          </ng-container>
        </dep-article-image-carousel>
      </ng-container>

      <!-- Image -->
      <ng-container *ngIf="podcastContent.imageId">
        <dep-article-image-carousel [carouselTitle]="podcastContent.imageTitle"
          [carouselSource]="podcastContent.imageSource">
          <dep-article-image-carousel-item [imageSrc]="podcastContent.imageUrl" [imageAlt]="podcastContent.altImage"
            *appCarouselSlide></dep-article-image-carousel-item>
        </dep-article-image-carousel>
      </ng-container>

      <!-- Document -->
      <ng-container *ngIf="podcastContent.documentId">
        <section>
          <dep-article-document [fileName]="podcastContent.documentTitle"
            [fileButtonName]="podcastContent.downloadButtonTitle" [fileURL]="podcastContent.documentUrl">
          </dep-article-document>
        </section>
      </ng-container>

      <!-- Podcast -->
      <ng-container *ngIf="podcastContent.podcastId">
        <dep-article-podcast [podcast]="podcastContent.iframe" id="myElem"></dep-article-podcast>
      </ng-container>

    </ng-container>

    <!-- Likes & vues -->
    <div class="actuality-data" [class.add-margin]="isOpenPopup && suggestions?.length == 0">
      <div class="actuality-data__container">
        <div class="actuality-data__item">
          <button (click)="likePodcast()" [disabled]="hasLiked">
            <img src="/assets/images/v-2024/icones/icon-like.svg" alt="icon like" class="customised">
          </button>
          <span>{{ podcast?.like | formatLikes }}</span>
        </div>
      </div>
      <!-- btn partager -->
      <dep-btn-cta-article [buttonIcon]="'icon-share'"  (buttonAction)="setPopupStatus($event)" [buttonText]="'Partager'" [isPopover]="true" [title]="podcast.title">
      </dep-btn-cta-article>
    </div>
  </div>
  <!-- Suggestions -->
  <section class="suggestions" *ngIf="suggestions?.length > 0">
    <div class="suggestions__container">
        <div class="section__header">
          <p class="title">Sur le même <strong class="highlight">thème</strong></p>
          <a class="ds-btn ds-btn--secondary" [routerLink]="'/actualites'">Voir toutes les actualités</a>
      </div>
        <div class="suggestions__cards">
            <ng-container *ngFor="let suggestion of suggestions| slice:0:3">
              <dep-suggestion-card [btnLibelle]="" [suggestion]="suggestion"></dep-suggestion-card>
            </ng-container>
        </div>
        <div class="actus__cta">
          <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/actualites'"
           >Voir toutes les actualités</a>
        </div>
    </div>
  </section>
</main>


<dep-scroll-to-top></dep-scroll-to-top>
<div *ngIf="isOpen">
  <dep-newsletter [newsletter]="newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-main-footer></dep-main-footer>